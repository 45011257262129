.navigation__overlay {
  @media (max-width: $screen-sm-max) {
    position: fixed;
    z-index: 101;
    top: -50%;
    right: 0;
    bottom: -50%;
    left: 0;
    background-color: rgba(0,0,0,0.7);
    visibility: hidden;
    opacity: 0;
    transition: 0.4s;

    &.active {
      visibility: visible;
      opacity: 1;
    }
  }
}

.navigation {
  @media (max-width: $screen-sm-max) {
    position: fixed;
    z-index: 102;
    top: 0;
    bottom: -50%;
    right: -100%;
    //width: 70%;
    height: 100vh;
    max-width: 300px;
    padding-top: 56px;
    padding-bottom: 15px;
    overflow: hidden;
    background: #aab7c7;
    transition: 0.2s;

    &.active {
      right: 0;
    }

    .menu {
      position: relative;
      z-index: 102;
      max-height: 100%;
      overflow-y: auto;
      padding-bottom: 100px;
    }

    ul {
      display: inline-block;
      padding-left: 0;
      text-align: right;
    }

    ul ul {

      a {
        padding-top: 5px;
        font-size: 0.8em;
      }
    }

    li {
      display: inline-block;
      width: 100%;
      margin: 0;
      height: auto;

      a {
        padding: 15px 22px 0 22px;
        margin: 0;
        display: block;
        font-weight: bold;
        font-size: 18px;
        text-transform: uppercase;
        background: transparent;
        color: #ffffff;
        text-decoration: none !important;

        &:hover {
          color: white;
          background: transparent;
          opacity: 0.7;
          text-decoration: underline !important;
        }
      }
    }
  }

  @media (min-width: $screen-lg) {
    float: left;
    width: 100%;
    background: transparent;
    margin-top: 2px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0;
        padding: 0;
        float: left;
        list-style: none;
        background: none;
        display: block;

        &.active {
          a {
            color: white;
            background: transparent;
            height: 40px;
            font-weight: bold;
          }
        }

        li {
          padding: 0;
          margin: 0;
          width: 100%;
        }

        ul {
          width: 230px;
          position: absolute;
          z-index: 99;
          left: -999em;
          height: auto;
        }

        ul ul {
          margin: 0;

          a {
            width: 100%;
          }
        }
      }

      ul {
        margin: 0;
        padding: 0;
      }
    }
  }
}



@media (min-width: $screen-lg) {
  .navigation ul li:hover ul ul,
  .navigation ul li:hover ul ul ul,
  .navigation ul li.sfhover ul ul,
  .navigation ul li.havechildsfhover ul ul,
  .navigation ul li.havechild-activesfhover ul ul,
  .navigation ul li.activesfhover ul ul,
  .navigation ul li.sfhover ul ul ul,
  .navigation ul li.havechildsfhover ul ul ul,
  .navigation ul li.havechild-activesfhover ul ul ul,
  .navigation ul li.activesfhover ul ul ul {
    left: -999em;
  }

  .navigation ul li:hover ul,
  .navigation ul li li:hover ul,
  .navigation ul li li li:hover ul,
  .navigation ul li.sfhover ul,
  .navigation ul li.havechildsfhover ul,
  .navigation ul li.havechild-activesfhover ul,
  .navigation ul li.activesfhover ul,
  .navigation ul li li.sfhover ul,
  .navigation ul li li.havesubchildsfhover ul,
  .navigation ul li li.havesubchild-activesfhover ul,
  .navigation ul li li.activesfhover ul,
  .navigation ul li li li.sfhover ul,
  .navigation ul li li li.havesubchildsfhover ul,
  .navigation ul li li li.havesubchild-activesfhover ul,
  .navigation ul li li li.activesfhover ul {
    left: auto;
  }

  .navigation li a {
    padding: 15px 22px 0 22px;
    margin: 0;
    display: block;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    background: transparent;
    color: #ffffff;
    height: 40px;
    text-decoration: none !important;

    &:hover {
      color: white;
      background: transparent;
      height: 40px;
      opacity: 0.7;
      text-decoration: underline !important;
    }
  }

  .navigation ul li ul {
    background: #93a4b8;
    margin-top: 0;
    text-align: left !important;
    width: 210px;
    opacity: 0.9;
  }

  .navigation ul li ul li {
    padding-bottom: 0px;
    background: none !important;
  }

  .navigation ul li ul li a {
    height: 35px !important;
    color: #ffffff !important;
    text-align: left !important;
    padding-left: 20px !important;

  }

  .navigation ul li ul li a:hover {
    color: white;
    background: transparent;
    width: 290px;

  }

  .navigation ul li ul li ul {
    margin-left: 200px;
    margin-top: -50px;
    width: 290px;
    text-align: left !important;
    padding-bottom: 0;
    background: #93a4b8;
    opacity: 0.9;
  }
}

