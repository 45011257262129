
.latestnewsenhanced_suf.newslist {
  padding-right: 15px;
  padding-left: 15px;
}

.latestnewsenhanced_suf .onecatlink {
  margin-top: 20px;
}

.latestnewsenhanced_suf .news {
  overflow: hidden;
  margin-top: 10px;
}

.latestnewsenhanced_suf .news:first-child {
  margin-top: 0;
}

.latestnewsenhanced_suf .innernews {
  overflow: hidden;
  padding: 2px;
}

.latestnewsenhanced_suf .even {
  /*background-color: #F4F4F4;*/
}

.latestnewsenhanced_suf .newshead {
  /* same column height fix */
  margin-bottom: -1000px;
  padding-bottom: 1000px;
}

.latestnewsenhanced_suf .headleft {
  float: left;
}

.latestnewsen hanced_suf .headright {
  float: right;
}

.latestnewsenhanced_suf .headnone {
  display: none;
}

.latestnewsenhanced_suf .newshead .calendar {
}

.latestnewsenhanced_suf .newshead .calendar.noimage {
  background: #F4F4F4; /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 0%, #e5e5e5 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #e5e5e5)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ffffff 0%, #e5e5e5 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ffffff 0%, #e5e5e5 100%); /* Opera11.10+ */
  background: -ms-linear-gradient(top, #ffffff 0%, #e5e5e5 100%); /* IE10+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e5e5e5', GradientType=0); /* IE6-9 */
  background: linear-gradient(top, #ffffff 0%, #e5e5e5 100%); /* W3C */
  color: #3D3D3D;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.latestnewsenhanced_suf .newshead .calendar .weekday,
.latestnewsenhanced_suf .newshead .calendar .month,
.latestnewsenhanced_suf .newshead .calendar .day,
.latestnewsenhanced_suf .newshead .calendar .year {
  position: relative;
  width: 100%;
  text-align: center;
}

.latestnewsenhanced_suf .newshead .calendar.noimage .weekday {
  background: #C8C8C8; /* Old browsers */
  background: -moz-linear-gradient(top, #eeeeee 0%, #cccccc 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #eeeeee), color-stop(100%, #cccccc)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #eeeeee 0%, #cccccc 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #eeeeee 0%, #cccccc 100%); /* Opera11.10+ */
  background: -ms-linear-gradient(top, #eeeeee 0%, #cccccc 100%); /* IE10+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#cccccc', GradientType=0); /* IE6-9 */
  background: linear-gradient(top, #eeeeee 0%, #cccccc 100%); /* W3C */
  color: #494949;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.latestnewsenhanced_suf .newshead .calendar .weekday {
  text-transform: uppercase;
  letter-spacing: 0.4em;
}

.latestnewsenhanced_suf .newshead .calendar .month {
  font-size: 0.8em;
  font-weight: bold;
  letter-spacing: 0.45em;
}

.latestnewsenhanced_suf .newshead .calendar .day {
  font-size: 1.8em;
  font-weight: bold;
  letter-spacing: 0.1em;
}

.latestnewsenhanced_suf .newshead .calendar .year {
  font-size: 0.7em;
  letter-spacing: 0.35em;
  min-height: 4px;
}

.latestnewsenhanced_suf .newshead .picture {
  overflow: hidden;
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  padding: 3px;
  text-align: center;
}

.latestnewsenhanced_suf .newshead .picture a,
.latestnewsenhanced_suf .newshead .nopicture a {
  text-decoration: none;
  display: inline-block;
  height: 100%;
  width: 100%;
  cursor: hand;
}

.latestnewsenhanced_suf .newshead .picture a:hover,
.latestnewsenhanced_suf .newshead .nopicture a:hover {
  text-decoration: none;
}

.latestnewsenhanced_suf .newshead .picture img {
  max-width: 100%;
  max-height: 100%;
}

.latestnewsenhanced_suf .newshead .picture .defaultpicture {
}

.latestnewsenhanced_suf .newshead .nopicture {
  overflow: hidden;
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  padding: 3px;
  text-align: center;
}

.latestnewsenhanced_suf .newshead .nopicture span {
  background-color: #F4F4F4;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.latestnewsenhanced_suf .newsinfo {
  border-bottom: 1px solid rgb(232, 207, 207);
  padding-bottom: 10px;
}

.latestnewsenhanced_suf .infoleft {
  clear: right;
}

.latestnewsenhanced_suf .inforight {
  clear: left;
}

.latestnewsenhanced_suf .newstitle {
  margin-left: 45px;
  font-weight: bold;
}

.latestnewsenhanced_suf .newsintro {
  margin-left: 45px;
  font-size: 14px;
}

.latestnewsenhanced_suf .newsextra {
  font-size: 0.9em;
  width: 30px;
  float: left;
  border-right: 1px solid #dc545c;
  line-height: 15px;
}

.latestnewsenhanced_suf .infoleft .newstitle {
  margin: 0 0 0 20px;
}

.latestnewsenhanced_suf .infoleft .newsintro {
  margin: 0 0 0 20px;
}

.latestnewsenhanced_suf .infoleft .newsextra {
  margin: 0 0 0 20px;
}

.latestnewsenhanced_suf .infoleft .link {
  margin: 0 0 0 20px;
}

.latestnewsenhanced_suf .infoleft .catlink {
  margin: 0 0 0 20px;
}

.latestnewsenhanced_suf .inforight .newstitle {
  margin: 0 20px 0 0;
  text-align: right;
}

.latestnewsenhanced_suf .inforight .newsintro {
  margin: 0 20px 0 0;
  text-align: right;
}

.latestnewsenhanced_suf .inforight .newsextra {
  margin: 0 20px 0 0;
  text-align: right;
}

.latestnewsenhanced_suf .inforight .link {
  margin: 0 20px 0 0;
  text-align: right;
}

.latestnewsenhanced_suf .inforight .catlink {
  margin: 0 20px 0 0;
  text-align: right;
}
