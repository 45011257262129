.page-kontakty {

  @media (max-width: $screen-sm-max) {
    .item-page > ymaps {
      width: 100% !important;
      height: 60vh !important;
    }

    .item-page .table-responsive {
      td {
        text-align: center;
      }

      td:nth-last-of-type(1),
      td:nth-last-of-type(2) {
        display: none;
      }

      .feedbackbutton {
        margin: 0 auto;
      }
    }
  }
}