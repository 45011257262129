.pull {
  list-style: none;
  width: 700px;
  border-left: 4px solid #d4232d;
  border-top: 1px solid white;
  border-bottom: 1px solid #bdbdbd;
  background: -webkit-gradient(linear, left top, left bottom, from(#f8f8f8), to(#f2f2f2));
  box-shadow: 0 1px 5px #bfbfbf;
  text-shadow: 0 1px 0 white;
  border-radius: 0;

  @media (max-width: $screen-sm-max) {
    width: 100%;
  }
}

.number {
  border-radius: 500px;
  background: #F13939;
  width: 30px;
  height: 27px;
  padding-top: 3px;
  text-align: center;
  font-size: 21px;
  color: #fff;
  float: left;
  margin-right: 20px;

  @media (max-width: $screen-sm-max) {
    width: 30px;
    height: 30px;
  }
}

.after_number {
  padding-left: 50px;
  line-height: 20px;

}

.after_number span {
  font-size: 18px;
  font-weight: bold;
}

.punkt {
  padding: 10px;
}

.punkt1 {
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  color: #b62830;
}