@media (max-width: $screen-sm-max) {
  #slide {
    display: none;
  }

  #artModClock1 {
    display: none;
  }

  #prefiks_v_kontente {
    margin-top: 30px;
  }
}