.body-overlayed embed,
.body-overlayed object,
.body-overlayed select {
  visibility:  hidden;

}

#sbox-window embed,
#sbox-window object,
#sbox-window select {
  visibility:  visible;

}

#sbox-overlay {
  position:absolute;
  background-color:#000;

}

#sbox-window {
  position:absolute;
  background:transparent;
  text-align:  left;
  overflow:visible;
  padding:10px;

}

* html #sbox-window {
  top: 50% !important;
  left: 50% !important;

}

#sbox-btn-close {
  position:absolute;
  width:30px;
  height:30px;
  right:-15px;
  top:-15px;
  border:  none;

}

.sbox-loading #sbox-content{
  background-position:center;

}

#sbox-content{
  clear:both;
  overflow:auto;
  height:  100%;
  width:100%;
  background:transparent;

}

.sbox-content-image#sbox-content{
  overflow:visible;

}

#sbox-image{
  display:block;

}

.sbox-content-image img{
  display:block;

}

.sbox-content-iframe#sbox-content{
  overflow:visible;

}


#sbox-window {

}

#sbox-btn-close{
  right: 10px !important;
  top: 10px !important;

}

#helpdiv{
  background:#fff;
  height:270px;
  border-radius: 10px;

}

#helpdiv h3{
  display:none;

}

#helpdiv a{
  font-size:11px;

}

#helpdiv ul li{
  margin:0 0 0 20px;

}

#helpdiv label{
  font-size:11px !important;

}

#helpdiv2{
  background:#9b4b4b;
  color: #fff;
  height: 36px;
  overflow: hidden;
  padding: 5px 7px;
  text-align:left;
  width:186px;
  border-radius: 10px;

}

#helpdiv2 .inputbox {
  width:163px;
  background:#efefef;
  color:#000;
  border-radius: 10px;

}

#loginbt{
  float:left;
  width: 250px;
  height: 30px;
  display:block;
  text-align:left;
  padding:0;
  font-style:italic;

}

#loginbt a{
  font-style:italic;
  background:url(../images/arrows.jpg) 0 3px no-repeat ;
  padding-left:11px;
  font-size:12px;

}

#loginbt a:hover{
  opacity : 0.7;
  -moz-opacity : 0.7;
  -ms-filter: "alpha(opacity=70)"; /* IE 8 */
  filter : alpha(opacity=70); /* IE < 8 */

}


#helpdiv .moduletable fieldset {
  border:none;

}

li.item-133.deeper.parent ul {
  width: 300px;
}

