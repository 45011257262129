#main {
  float: left;
  width: 700px;
  margin: 15px 0 0 25px;
  padding: 0 0 25px 0;
  background: transparent;
  margin-top: 0;
  text-align: justify;

  @media (max-width: $screen-xs-max) {
    float: none;
    width: 100%;
    margin: 0;
    margin-top: 60px;
    padding: 0 10px;
  }

  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    float: left;
    width: 60%;
    margin: 15px 0 25px;
  }
}

.front-page #main {
  @media (max-width: $screen-sm-max) {
    margin-top: 0;
  }
}

#main-full {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  float: left;
  width: 950px;
  background: transparent;
  margin-top: 0;
  text-align: justify;

  @media (max-width: $screen-sm-max) {
    float: none;
    width: 100%;
    padding: 10px;
  }

  @media (max-width: $screen-xs-max) {
    margin-top: 50px;
  }

  @media (min-width: $screen-lg) {
    margin: 15px 0 0 25px;
  }
}

.front-page #main-full {
  @media (max-width: $screen-sm-max) {
    margin-top: 0;
  }
}