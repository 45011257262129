.item-page {
  @media (max-width: $screen-sm-max) {

    h1,
    h2,
    h3 {
      display: inline-block;
      padding: 0 20px;
      color: #647b98;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      border-bottom: 1px solid #e8e9e9;
    }
  }
}

