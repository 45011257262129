@media (max-width: $screen-sm-max) {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  #wrapper {
    h1,h2,h3,h4 {
      height: auto;
      width: 100%;
    }

    h2, h3, h4 {
      border: none;
    }
  }

  img {
    max-width: 100%;
  }

  .moduletable,
  .custom {
    &--desktop {
      display: none !important;
    }
  }

  html {
    &.blocked {
      &,
      & body {
        position: fixed;
        overflow: hidden;
      }
    }
  }
}


@media (min-width: $screen-lg) {
  .moduletable,
  .custom {
    &--mobile {
      display: none !important;
    }
  }
}
