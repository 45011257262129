#wrapper {
  display: inline-block;
  width: 100%;
  background: white;
  overflow: hidden; //временно

  @media (max-width: $screen-sm-max) {
    margin-top: 50px;
  }
  @media (min-width: $screen-sm) {
    width: $screen-sm;
    width: calc(100% - 30px);
    padding: 0 20px;

  }

  @media (min-width: $screen-lg) {
    margin-top: 57px;
    padding: 0;
    border: 1px solid #bbbbbb;
    width: $screen-lg;
  }
}