#box-wrap {

  @media (min-width: $screen-lg) {
    float: left;
    width: 1000px;
    margin: 0;
    padding: 0 0 25px 25px;
    background: transparent;
    text-align: left;
  }
}

.box {
  h3,
  .h3 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: bold;
    text-transform: uppercase;
    background: transparent;
    text-align: center;
    color: #b62830;
  }

  .h3 {
    margin-top: 29px;
  }

  @media (min-width: $screen-lg) {
    float: left;
    margin-left: 8px;
    padding-right: 18px;
    width: 935px;
    border-bottom: 1px solid #e5e5e5;

    h3,
    .h3 {
      height: 30px;
    }
  }
}