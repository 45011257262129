.bfQuickMode {
  @media (max-width: $screen-sm-max) {
    margin-bottom: 50px;
    text-align: center;

    .bfSubmitButton {
      margin-top: 20px;
    }

    .bfRequired {
      position: absolute;
    }
  }
}