.newslist {
  @media (max-width: $screen-sm-max) {
    margin: 0 10px;
    padding-top: 70px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #bdbdbd;
    background: -webkit-gradient(linear,left top,left bottom,from(#f8f8f8),to(#f2f2f2));
    box-shadow: 0 1px 5px #bfbfbf;
    text-shadow: 0 1px 0 #fff;
    border-radius: 0;

    .news {
      border-top: 1px solid #e8e9e9;
      padding: 20px 0;
    }

    .newsinfo {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
    }

    .newsintro {
      padding: 10px;
      text-align: left;
    }

    .newsextra {
      display: inline-block;
      float: left;
      width: 20%;
      padding-left: 10px;
      text-align: left;
    }

    .newsdate {
      display: inline-block;
      width: 45px;
      line-height: 1.3;
      text-align: left;
      border-right: 1px solid #b5282f;
    }

    .newstitle {
      display: inline-block;
      float: left;
      width: 80%;
      padding-right: 10px;
      padding-left: 15px;
      color: #343434;
      font-size: 18px;
      font-weight: 700;
      text-align: left;

      a {
        text-decoration: none !important;
      }
    }
  }

}