/**
 * Footer block ¯\_(ツ)_/¯
 */

#ft {
  @media (min-width: $screen-lg) {
    float: left;
    width: 100%;
    height: 58px;
    background: url(../images/topmenu.jpg) 0 0 repeat-x;
  }
}

.ftb {
  @media (max-width: $screen-sm-max) {
    width: auto;

    table {
      display: none;
    }

    a,
    span {
      display: inline-block;
      width: 100%;
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
    }

    .footer {
      margin: 0 10px;
      padding: 30px 10px 20px;
      background-color: #aab7c7;
    }

    .footer__credits {
      display: inline-block;
      padding-top: 15px;
      padding-bottom: 10px;

      &,
      a:link,
      a:visited {
        width: auto;
        color: #647b98;
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        text-decoration: none;
      }
    }
  }

  @media (min-width: $screen-lg) {
    margin-top: 13px;
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    width: 940px;
    float: left;
    color: #fff;

    a {
      &,
      &:visited {
        padding: 0 !important;
        border: none !important;
        color: #fff;
        text-decoration: none;
      }

      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }

    .footer--mobile,
    .footer__credits {
      display: none;
    }
  }
}


