#box-wrap {
  background: transparent;
  text-align: left;

  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    clear: both;
    padding-top: 20px;
  }

  @media (min-width: $screen-lg) {
    float: left;
    width: 1000px;
    margin: 0;
    padding: 0 0 25px 25px;
  }
}

.box {

  @media (min-width: $screen-lg) {
    float: left;
    margin-left: 8px;
    padding-right: 18px;
    width: 935px;
  }
}

.box h3 {
  font-size: 18px;
  height: 30px;
  margin-bottom: 15px;
  font-weight: bold;
  text-transform: uppercase;
  background: transparent;
  text-align: center;
  color: #b62830;

  @media (min-width: $screen-lg) {

  }
}
