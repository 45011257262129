html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  text-align: center;
  background: #4f5150 url(../images/bg.jpg) 0 0 repeat;
  font-size: 16px;
  color: #343434;
  font-family: 'PT Sans Narrow', Arial, Helvetica, sans-serif;
  line-height: 150%;
}

p {
  font-size: 100%;
}

h1 {
  font-size: 22px;
  height: 30px;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  background: transparent;
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
  color: #b62830;
  margin-top: 29px;
}

h2 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  background: transparent;
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
  color: #b62830;
  margin-top: 29px;

  @media (min-width: $screen-lg) {
    height: 30px;
  }
}

h3 {
  @media (min-width: $screen-lg) {
    font-size: 18px;
    height: 30px;
    margin-bottom: 0;
    font-weight: bold;
    text-transform: uppercase;
    background: transparent;
    border-bottom: 1px solid #e5e5e5;
    text-align: center;
    color: #b62830;
    margin-top: 29px;
  }
}

.vakan {
  font-size: 18px;
  height: 30px;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  background: transparent;
  border-bottom: 1px solid #e5e5e5;
  text-align: left;
  color: #000000;
  margin-top: 29px;
}

h4, h5, h6 {
  font-size: 18px;
  height: 30px;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  background: transparent;
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
  color: #b62830;
  margin-top: 29px;
}

/* liens hypertextes*/

a:link, a:visited {
  text-decoration: underline;
  color: #343434;
  font-weight: normal;
}

a:hover {
  text-decoration: none;
}

ul {
  margin: 0;
  padding-left: 20px;
}

li {
  list-style-type: disc;
  background: transparent;
  margin: 4px;
}

img {
  border: none;
  margin: 3px 5px;
  padding: 0;
}

img:hover {
  opacity: 0.7;
}

img .caption {
  text-align: left;
}

.highlight {
  background: #87a544;
  padding: 0 2px 0 2px;
  color: #ffffff;
  border-radius: 6px 6px 6px 6px;
}

.button {
  background: #87a544;
  border: none;
  color: #ffffff;
  font-weight: bold;
  padding: 4px;
  border-radius: 6px 6px 6px 6px;
}

.inputbox {
  color: #ffffff;
  background: #87a544;
  border: none;
  padding: 4px;
  font-weight: bold;
  border-radius: 6px 6px 6px 6px;
}

fieldset {
  margin: 5px;
  padding: 5px;
  border: none;
}

/*******************/

@media (min-width: $screen-lg) {
  .navigation {
    float: left;
    width: 100%;
    background: transparent;
    margin-top: 2px;
  }

  .navigation ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .navigation ul ul {
    margin: 0;
    padding: 0;
  }

  .navigation ul li {
    margin: 0;
    padding: 0;
    float: left;
    list-style: none;
    background: none;
    display: block;
  }

  .navigation ul li ul {
    width: 230px;
    position: absolute;
    z-index: 99;
    left: -999em;
    height: auto;
  }

  .navigation ul li ul ul {
    margin: 0;
  }

  .navigation ul li li {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .navigation ul ul a {
    width: 100%;
  }

  .navigation ul li:hover ul ul, .navigation ul li:hover ul ul ul, .navigation ul li.sfhover ul ul, .navigation ul li.havechildsfhover ul ul, .navigation ul li.havechild-activesfhover ul ul, .navigation ul li.activesfhover ul ul, .navigation ul li.sfhover ul ul ul, .navigation ul li.havechildsfhover ul ul ul, .navigation ul li.havechild-activesfhover ul ul ul, .navigation ul li.activesfhover ul ul ul {
    left: -999em;
  }

  .navigation ul li:hover ul, .navigation ul li li:hover ul, .navigation ul li li li:hover ul, .navigation ul li.sfhover ul, .navigation ul li.havechildsfhover ul, .navigation ul li.havechild-activesfhover ul, .navigation ul li.activesfhover ul, .navigation ul li li.sfhover ul, .navigation ul li li.havesubchildsfhover ul, .navigation ul li li.havesubchild-activesfhover ul, .navigation ul li li.activesfhover ul, .navigation ul li li li.sfhover ul, .navigation ul li li li.havesubchildsfhover ul, .navigation ul li li li.havesubchild-activesfhover ul, .navigation ul li li li.activesfhover ul {
    left: auto;
  }

  .navigation ul li a {
    padding: 15px 17px 0;
    margin: 0;
    display: block;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    background: transparent;
    color: #ffffff;
    height: 40px;
    text-decoration: none !important;
  }

  .navigation ul li a:hover {
    color: #b62830;
    background: transparent;
    height: 40px;
    text-decoration: underline !important;
  }

  .navigation ul li.active a {
    color: #b62830;
    background: transparent;
    height: 40px;
  }

  .navigation ul li ul {
    background: #93a4b8;
    margin-top: 0;
    text-align: left !important;
    width: 200px;
    opacity: 0.9;
  }

  .navigation ul li ul li {
    padding-bottom: 0;
    background: none !important;
  }

  .navigation ul li ul li a {
    height: 35px !important;
    color: #ffffff !important;
    text-align: left !important;
    padding-left: 20px !important;
  }

  .navigation ul li ul li a:hover {
    color: #b62830 !important;
    background: transparent;
    width: 290px;
  }

  .navigation ul li ul li ul {
    margin-left: 200px;
    margin-top: -50px;
    width: 290px;
    text-align: left !important;
    padding-bottom: 0;
    background: #93a4b8;
    opacity: 0.9;
  }
}

#sitename {
  @media (min-width: $screen-lg) {
    float: left;
    width: 100%;
    height: 140px;
    background: url(../images/sitename.png) 0 100% no-repeat;
  }
}

#sitename-left {
  @media (min-width: $screen-lg) {
    float: left;
    width: 250px;
    height: 140px;
    background: url(../images/bg-sitename.png) 100% 0 no-repeat;
  }
}

#toolitem {
  float: left;
  width: 200px;
  height: 30px;
  background: transparent;
  margin-top: 50px;
  margin-left: 50px;
}

#search {
  float: left;
  width: 189px;
  height: 29px;
  background: url(../images/search.jpg) 0 0 no-repeat;
  margin-left: 50px;
  margin-top: 5px;
}

#search .inputbox {
  background: none;
  color: #343434;
}

#sitename-center {

  @media (min-width: $screen-lg) {
    float: left;
    width: 465px;
    height: 140px;
    background: url(../images/bg-sitename.png) 100% 0 no-repeat;
  }
}

#logo {
  float: left;
  margin-top: -3px;
  margin-left: 10px;
}

p.zvonok {
  margin-left: 16px;
}

p.zayavka {
  margin-left: 14px;
}

#sitename-right {
  float: left;
  width: 280px;
  height: 140px;
  background: transparent;
}

#title-item {
  float: left;
  width: 200px;
  height: 20px;
  background: transparent;
  margin-top: 50px;
  margin-left: 40px;
  text-align: left;
}

#title-item span {
  font-style: italic;
  background: url(../images/arrows.jpg) 0 3px no-repeat;
  padding-left: 11px;
  font-size: 12px;
  text-transform: capitalize;
}

#social-item {
  float: left;
  width: 1px;
  height: 40px;
  background: transparent;
  margin-left: 40px;
  text-align: left;
}

#facebook, #twitter, #linkedin {
  float: left;
  width: 36px;
  height: 36px;
}

/*******************/

#slide {
  float: left;
  width: 1000px;
  height: 240px;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  background: #ffffff url(../images/sitename.png) 0 100% no-repeat;
}

/* The Nivo Slider styles */

.nivoSlider {
  float: left;
  position: relative;
  width: 1000px;
  height: 240px;
  margin-left: 0;
  margin-top: 0;
}

.nivoSlider img {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 1000px; /* Make sure your images are the same size */
  height: 240px; /* Make sure your images are the same size */
}

.nivoSlider a {
  border: 0;
  display: block;
  color: #ffffff;
}

/* If an image is wrapped in a link */

.nivoSlider a.nivo-imageLink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  z-index: 6;
  display: none;
}

/* The slices and boxes in the Slider */

.nivo-slice {
  display: block;
  position: absolute;
  z-index: 5;
  height: 100%;
}

.nivo-box {
  display: block;
  position: absolute;
  z-index: 5;
}

/* Caption styles */

.nivo-caption {
  position: absolute;

  /*left: 60px;*/

  /*right:0px;*/

  bottom: 30px;
  background: #ffffff;
  border-right: 1px solid #a2aebc;
  border-bottom: 1px solid #a2aebc;
  border-top: 1px solid #a2aebc;
  color: #000000;
  z-index: 8;
  width: 350px;
  padding: 20px;
  overflow: hidden;
}

.nivo-caption p {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 19px;
}

.nivo-caption a {
  display: inline !important;
  border-bottom: 1px dotted #ffffff;
}

.nivo-caption a:hover {
  border-bottom-style: solid;
  text-decoration: none;
}

.nivo-html-caption {
  display: none;
}

/* Direction nav styles (e.g. Next & Prev) */

.nivo-directionNav a {
  position: absolute;
  top: 43%;
  z-index: 9;
  cursor: pointer;
  text-indent: -10000px;
  background-image: url(../images/direction.png);
  background-repeat: no-repeat;
  width: 51px;
  height: 72px;
}

.nivo-prevNav {
  left: 0;
  background-position: left top;
}

.nivo-nextNav {
  right: -10px;
  background-position: -31px top;
}

.nivo-controlNav {
  display: none;
}

.nivo-controlNav a {
  display: none;
}

/**************************/

#main {

  @media (min-width: $screen-lg) {
    float: left;
    width: 700px;
    margin: 15px 0 0 25px;
    padding: 0 0 25px 0;
    background: transparent;
    margin-top: 0;
    text-align: justify;
  }
}

#main-full {
  @media (min-width: $screen-lg) {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    float: left;
    width: 950px;
    margin: 15px 0 0 25px;
    padding: 0 0 25px 0;
    background: transparent;
    margin-top: 0;
    text-align: justify;
  }
}

/*************************/

ul.actions {
  background: transparent;
  margin: 0;
}

ul.actions li {
  list-style: none;
  float: right;
}

dt.article-info-term {
  display: none;
}

dl.article-info dd {
  margin: 0;
  font-size: 12px;
  font-style: italic;
}

.items-leading {
  width: 100%;
  font-weight: bold;
  padding: 0;
}

.items-row {
  width: 100%;
  font-weight: bold;
  padding: 0;
}

/***titre frontpage*****/

.items-leading h2 {
  font-size: 18px;
  height: 30px;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: capitalize;
  border-bottom: 1px solid #e5e5e5;
}

.items-row h2, .items-row .h2 {

  @media (min-width: $screen-lg) {
    font-size: 18px;
    height: 30px;
    margin-bottom: 0;
    font-weight: bold;
    text-transform: capitalize;
    border-bottom: 1px solid #e5e5e5;
  }
}

.items-leading .h2, .items-row .h2 {

  @media (min-width: $screen-lg) {
    margin-top: 29px !important;
    text-align: left;
    border-bottom: 1px solid #e5e5e5;
    height: 30px;
  }
}

.items-row h2 {
  margin-top: 29px;
  text-align: center;
}

.items-row .h2 a {
  color: #b62830;
  font-weight: bold;
}

div.h2 > .post_title {
  font-size: 20px;
  color: #b62830;
  margin-top: 29px;
  font-weight: bold;
}

/*****separation des articles******/

.item-separator {
  height: 30px;
  width: 100%;
  display: block;
}

.item-page {
  width: 100%;
  font-weight: normal;
  padding: 0 0 10px 0;
}

.item-page h2 {
  font-size: 18px;
  height: 30px;
  margin-bottom: 0;
  font-weight: bold;
  text-transform: uppercase;
  background: transparent;
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
  color: #b62830;
  margin-top: 29px;
}

.readmore a {
  float: right;
  font-weight: bold;
  padding: 5px;
  margin-right: 15px;
  color: #ffffff;
  margin-top: 5px;
  font-weight: bold;
  background: #87a544;
  border-radius: 6px;
}

.readmore a:hover {
  color: #ffffff;
  background: #343434;
}

div.cols-1 .item {
  width: 100%;
}

.categories-list li {
  list-style: none;
}

.item-title {
  font-size: 18px;
  height: 30px;
  margin-bottom: 0;
  font-weight: bold;
  background: transparent;
  padding-top: 5px;
  text-transform: capitalize;
}

/* ++++++++++++++  items more  ++++++++++++++ */

.items-more {
  padding-top: 10px;
}

.items-more h3 {
  font-weight: bold;
  margin: 0;
  padding: 5px 5px 5px 5px;
  font-size: 1.4em;
}

.items-more ol {
  line-height: 1.3em;
  margin: 0 0 0 20px;
  padding: 5px 0 5px 2px;
}

.items-more ol li {
  padding: 2px;
}

.items-more ol li a {
  display: inline;
  margin: 0;
  font-weight: bold;
  padding: 5px 5px 5px 5px;
}

.items-more ol li a:hover, .items-more ol li a:active, .items-more ol li a:focus {
  font-weight: normal;
  padding: 5px 5px 5px 5px;
  text-decoration: none;
}

/* Pagination ---*/

.counter {
  text-align: center;
}

//
//#right {
//  float: left;
//  width: 240px;
//  text-align: left;
//  padding-left: 20px;
//  margin-top: 30px;
//  margin-bottom: 30px;
//  margin-left: 10px;
//}
//
//#right h3 {
//  margin-bottom: 15px;
//  margin-top: 0;
//  text-transform: uppercase;
//  font-size: 18px;
//  color: #b62830;
//  padding-left: 25px;
//}
//
//#right ul {
//  padding-left: 20px;
//}
//
//#right .moduletable_menu, #right .moduletable {
//  margin-bottom: 30px;
//  padding-left: 15px;
//  padding-bottom: 20px;
//}

/***********************/

#ft {
  @media (min-width: $screen-lg) {
    float: left;
    width: 100%;
    height: 58px;
    background: url(../images/topmenu.jpg) 0 0 repeat-x;
  }
}

#top {
  float: left;
  width: 30px;
  height: 30px;
  margin-top: 9px;
  padding: 0 !important;
  border: none !important;
  background: transparent;
}

.top_button {
  padding: 0 !important;
  border: none !important;
}

.prefiks {
  font-size: 25px;
  color: #000000;
  display: block;
  position: absolute;
  left: 722px;
  top: 102px;
  z-index: 999;
}

.telefon {
  font-size: 30px;
  color: #b62830;
  display: block;
  position: absolute;
  left: 815px;
  top: 100px;
  z-index: 999;
  line-height: 100%;
}

.prefiks2 {
  font-size: 25px;
  color: #000000;
  display: block;
  position: absolute;
  left: 722px;
  top: 72px;
  z-index: 999;
}

.telefon2 {
  font-size: 30px;
  color: #b62830;
  display: block;
  position: absolute;
  left: 815px;
  top: 70px;
  z-index: 999;
  line-height: 100%;
}

.phone {
  display: block;
  position: absolute;
  left: 936px;
  top: 72px;
  z-index: 999;
}

.directionmap {
  display: block;
  position: absolute;
  left: 935px;
  top: 135px;
  z-index: 999;
}

.zayavka a, p.zayavka a {
  position: relative;
  display: block;
  margin: 0 auto;
  margin-top: 15px;
  padding: 0 20px 0 17px;
  line-height: 38px;
  border-top: 1px solid white;
  border-bottom: 1px solid #bdbdbd;
  background: -webkit-gradient(linear, left top, left bottom, from(#f8f8f8), to(#f2f2f2));
  box-shadow: 0 1px 5px #bfbfbf;
  text-shadow: 0 1px 0 white;
  border-radius: 0;
  width: 160px;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;

  @media (min-width: $screen-lg) {
    height: 38px;
    margin-top: -56px;
    margin-left: 221px;
  }
}

.zayavka a:hover, p.zayavka a:hover {
  text-decoration: underline;
  color: #b5282f;
}

.zayavka a:visited, p.zayavka a:visited {
  text-decoration: none;
}

.adress {
  font-size: 16px;
  color: #000000;
  display: block;
  position: absolute;
  left: 722px;
  top: 134px;
  z-index: 999;
}

.adress a, p.adress a {
  text-decoration: none;
}

.adress a:hover, p.adress a:hover {
  text-decoration: underline;
  color: #b5282f;
}

.adress a:visited, p.adress a:visited {
  text-decoration: none;
}

.pochta {
  font-size: 16px;
  color: #b62830;
  display: block;
  position: absolute;
  left: 742px;
  top: 160px;
  z-index: 999;
}

.pochta a, p.pochta a {
  text-decoration: none;
}

.pochta a:hover, p.pochta a:hover {
  text-decoration: underline;
  color: #b5282f;
}

.pochta a:visited, p.pochta a:visited {
  text-decoration: none;
}

.mail {
  display: block;
  position: absolute;
  left: 125px;
  top: 135px;
  z-index: 999;
}

.zvonok a, p.zvonok a {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px 0 17px;
  line-height: 38px;
  height: 38px;
  border-top: 1px solid white;
  border-bottom: 1px solid #bdbdbd;
  background: -webkit-gradient(linear, left top, left bottom, from(#f8f8f8), to(#f2f2f2));
  box-shadow: 0 1px 5px #bfbfbf;
  text-shadow: 0 1px 0 white;
  border-radius: 0;
  margin-top: 83px;
  margin-left: 2px;
  font-size: 14px;
  width: 160px;
  text-transform: uppercase;
  text-decoration: none;
}

.zvonok a:hover, p.zvonok a:hover {
  text-decoration: underline;
  color: #b5282f;
}

.zvonok a:visited, p.zvonok a:visited {
  text-decoration: none;
}

.feedbackbutton, p.feedbackbutton a {
  border: 1px solid #bbbbbb;
  color: #ffffff !important;
  font-family: 'PT Sans Narrow', sans-serif !important;
  font-size: 19px;
  text-decoration: none !important;
  text-align: center;
  line-height: 44px;
  display: block;
  width: 235px;
  height: 44px;
  font-style: normal;
  text-transform: uppercase;
  border-radius: 2px;
  margin-bottom: 50px;
  border-top: 1px solid white;
  border-bottom: 1px solid #bdbdbd;
  background: -webkit-gradient(linear, left top, left bottom, from(#c27a7e), to(#b62830));
  box-shadow: 0 1px 5px #bfbfbf;
  background: #b62830;
}

.feedbackbutton a:hover, p.feedbackbutton a:hover {
  text-decoration: underline !important;
}

.feedbackbutton a:visited, p.feedbackbutton a:visited {
  text-decoration: none !important;
}

.pagenav {
  display: none !important;
}

.zayavkabutton {
  @media (max-width: $screen-sm-max) {
    margin: 0;
  }
}

.zayavkabutton a {
  border: 1px solid #bbbbbb;
  color: #ffffff !important;
  font-family: 'PT Sans Narrow', sans-serif !important;
  font-size: 17px;
  text-decoration: none !important;
  text-align: center;
  line-height: 44px;
  display: block;
  width: 208px;
  height: 44px;
  font-style: normal;
  text-transform: uppercase;
  border-radius: 2px;
  margin-bottom: -10px;
  border-top: 1px solid white;
  border-bottom: 1px solid #bdbdbd;
  background: -webkit-gradient(linear, left top, left bottom, from(#c27a7e), to(#b62830));
  box-shadow: 0 1px 5px #bfbfbf;
  background: #b62830;

  @media (max-width: $screen-sm-max) {
    margin: 0 auto;
    margin-top: 20px;
  }
}

.zayavkabutton a:hover, p.zayavkabutton a:hover {
  text-decoration: underline !important;
}

.zayavkabutton a:visited, p.zayavkabutton a:visited {
  text-decoration: none !important;
}

.uslugi, p.uslugi a {
  text-decoration: none !important;
  margin-top: 0;
  font-size: 18px;
  margin-bottom: 3px;
}

.uslugi a:hover, p.uslugi a:hover {
  text-decoration: underline !important;
  color: #b62830;
}

.uslugi a:visited, p.uslugi a:visited {
  text-decoration: none !important;
}

.uslugibutton {
  @media (max-width: $screen-sm-max) {
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
  }
}

.uslugibutton a {
  border: 1px solid #bbbbbb;
  color: #ffffff !important;
  font-family: 'PT Sans Narrow', sans-serif !important;
  font-size: 19px;
  text-decoration: none !important;
  text-align: center;
  line-height: 50px;
  display: block;
  font-style: normal;
  text-transform: uppercase;
  border-radius: 2px;
  border-top: 1px solid white;
  border-bottom: 1px solid #bdbdbd;
  background: -webkit-gradient(linear, left top, left bottom, from(#c27a7e), to(#b62830));
  box-shadow: 0 1px 5px #bfbfbf;
  background: #b62830;

  @media (max-width: $screen-sm-max) {
    padding: 10px 10px 8px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
  }

  @media (min-width: $screen-lg) {
    width: 350px;
    height: 50px;
    margin-bottom: 30px;
  }
}

.uslugibutton a:hover {
  text-decoration: underline !important;
}

.uslugibutton a:visited {
  text-decoration: none !important;
}

.pluso-010010011001-06 a.pluso-more {
  display: none !important;
}

ul.nice-menu {
  list-style: none;
  margin-top: 30px;
  width: 180px;
}

ul.nice-menu li {
  width: 5px;
  height: 27px;
  line-height: 20px;
  padding: 0 0 0 0;
  margin-top: 3px;
  background: transparent;
}

ul.nice-menu.tight li {
  margin-top: 0 !important;
}

ul.nice-menu li {
  animation-name: shrink;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
}

ul.nice-menu.bounce li {
  animation-name: shrink-bounce;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
}

ul.nice-menu li:hover {
  width: 180px;
  padding-left: 10px;
  animation-name: expand;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
}

ul.nice-menu.bounce li:hover {
  animation-name: expand-bounce;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
}

ul.nice-menu a {
  width: 180px;
  text-decoration: none;
  font-size: 16px;
  color: black;
  font-weight: normal;
  position: absolute;
  padding: 2px 0;
  padding-left: 10px;
}

ul.nice-menu a:hover {
  color: white;
  text-decoration: underline;
}

ul.nice-menu li.green {
  background: #b62830;
  margin-left: 0;
}

.zvonokbutton {
  @media (max-width: $screen-sm-max) {
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
  }
}

.zvonokbutton a {
  border: 1px solid #bbbbbb;
  color: #ffffff !important;
  font-family: 'PT Sans Narrow', sans-serif !important;
  font-size: 19px;
  text-decoration: none !important;
  text-align: center;
  line-height: 50px;
  display: block;
  font-style: normal;
  text-transform: uppercase;
  border-radius: 2px;
  margin-bottom: 30px;
  border-top: 1px solid white;
  border-bottom: 1px solid #bdbdbd;
  background: -webkit-gradient(linear, left top, left bottom, from(#c27a7e), to(#b62830));
  box-shadow: 0 1px 5px #bfbfbf;
  background: #b62830;

  @media (max-width: $screen-sm-max) {
    margin-left: 0 !important;
    padding: 10px 10px 8px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
  }

  @media (min-width: $screen-lg) {
    width: 350px;
    height: 50px;
  }
}

.zvonokbutton a:hover {
  text-decoration: underline !important;
}

.zvonokbutton a:visited {
  text-decoration: none !important;
}

::selection {
  background: #ffeded;
}

.uslugu_v_tablice {
  text-align: center;
  text-transform: uppercase;
}

.ceny_v_tablice {
  text-transform: none;
  color: #b62830;
  font-weight: bold;
}

.strong {
  font-weight: bold;
}

@media (min-width: $screen-lg) {
  .pibox1 {
    padding: 10px;
    background-color: #dfe8f3;
    border: 2px solid #babfc4;
  }

  .pibox1 table {
    margin: 0 auto;
  }

  .pibox1 td {
    padding: 0 10px;
  }

  .pibox2 {
    margin-top: 20px;
    padding: 10px;
    background-color: #eaf0f7;
    border: 2px solid #b1b4b6;
  }

  .pibox2 span {
    font-weight: bold;
  }
}

.blog {
  padding-top: 20px;
}