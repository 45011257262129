ul.pagination {
  width: 100%;
  padding: 10px 0;
  text-align: center;

  @media (min-width: $screen-lg) {
    float: left;
  }
}

ul.pagination li {
  background: none;
  display: inline-block;
  padding: 6px;
  margin: 0 3px;
  overflow: hidden;

  @media (max-width: $screen-sm-max) {
    margin-bottom: 5px;
  }

  @media (min-width: $screen-lg) {
    display: inline;
  }
}

ul.pagination li span {
  padding: 6px;
  line-height: 20px;
}

ul.pagination a {
  background: #343434;
  color: #ffffff;
  line-height: 20px;
  padding: 6px;
  text-decoration: none;
  border-radius: 10px;
}

ul.pagination a:hover, ul.pagination a:active, ul.pagination a:focus {
  text-decoration: none;
  color: #ffffff;
  background: #87a544;
}

