.item-page {
  h1 {
    padding-bottom: 18px;
    border-bottom: 1px solid #e8e9e9;
  }
}

.glavbut a,
.glavbut1 a {
  position: relative;
  display: block;
  margin: 0;
  text-align: center;
  padding: 0 20px 0 17px;
  line-height: 58px;
  height: 58px;
  border-top: 1px solid white;
  border-bottom: 1px solid #bdbdbd;
  background: -webkit-gradient(linear, left top, left bottom, from(#f8f8f8), to(#f2f2f2));
  box-shadow: 0 1px 5px #bfbfbf;
  text-shadow: 0 1px 0 white;
  border-radius: 0;
  margin-top: 40px;
  margin-left: 2px;
  font-size: 18px;
  font-weight: bold;
  width: 300px;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: #b5282f;
  }

  &:visited {
    text-decoration: none;
  }

  @media (max-width: $screen-sm-max) {
    width: 100%;
    height: auto;
    margin-top: 18px;
    font-size: 16px;
  }
}

.glavbut1 a {
  @media (min-width: $screen-lg) {
    margin-top: -76px;
    margin-left: 365px;
  }
}