.topmenu,
#topmenu {

  @media (max-width: $screen-sm-max) {
    position: relative;
    z-index: 1;
    border-bottom: 1px solid #e8e8e8;
    background-color: white;
  }

  @media (min-width: $screen-lg) {
    float: left;
    width: 100%;
    height: 58px;
    background: url(../images/topmenu.jpg) 0 0 repeat-x;
    margin-top: 0;
    position: fixed;
    z-index: 9999;
  }
}

.topmenu__wrapper {
  position: relative;
  z-index: 103;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: white;

  @media (max-width: $screen-sm-max) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #e8e8e8;
  }

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.topmenu__logo {
  display: inline-block;

  img {
    width: 100%;
  }
}

.topmenu__button {
  display: inline-block;
  width: 22px;
  height: 22px;
  font-size: 0;
  border: none;
  background-color: transparent;
  background-position: center;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='23' height='22' viewBox='0 0 23 22'%3E%3Cdefs%3E%3Cpath id='a' d='M279 32c0-1 1-2 2-2h17a2 2 0 1 1 0 4h-17a2 2 0 0 1-2-2z'/%3E%3Cpath id='b' d='M279 24c0-1 1-2 2-2h17a2 2 0 1 1 0 4h-17a2 2 0 0 1-2-2z'/%3E%3Cpath id='c' d='M279 16c0-1 1-2 2-2h17a2 2 0 1 1 0 4h-17a2 2 0 0 1-2-2z'/%3E%3C/defs%3E%3Cuse fill='%23647b98' xlink:href='%23a' transform='translate%28-278 -13%29'/%3E%3Cuse fill='%23647b98' xlink:href='%23b' transform='translate%28-278 -13%29'/%3E%3Cuse fill='%23647b98' xlink:href='%23c' transform='translate%28-278 -13%29'/%3E%3C/svg%3E");
}