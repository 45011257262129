#sitename {
  width: 100%;
  padding: 0 10px;

  @media (min-width: $screen-lg) {
    float: left;
    height: 140px;
    padding: 0;
    background: url(../images/sitename.png) 0 100% no-repeat
  }
}

.sitename__contacts {
  display: none;

  @media (max-width: $screen-xs-max) {
    position: absolute;
    left: 0;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
  }

  @media (max-width: $screen-sm-max) {
    display: flex;
    width: 100%;
    padding: 10px 20px;
    border-bottom: 1px solid #e8e8e8;
    background-color: white;
  }
}

.sitename__address {
  width: 55%;
  color: #052228;
  font-size: 14px;
  font-weight: 400;
  text-align: left;

  @media (max-width: $screen-sm-max) {
    display: flex;
    align-items: center;
  }
}

.sitename__phones {
  width: 45%;

  a {
    display: block;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    color: #052228;

    span {
      color: #b62830;
      font-weight: 700;
      letter-spacing: 0.35px;
    }
  }

  @media (max-width: $screen-sm-max) {
    text-align: right;
  }
}

@media (max-width: $screen-sm-max) {
  .sitename__center {
    display: none;
  }

  .front-page .sitename__center {
    display: block;
  }
}

.sitename__center {
  background: url(../images/header-bg.jpg) center no-repeat;
  background-size: cover;

  .sitename__header {
    display: none;
  }

  @media (max-width: $screen-sm-max) {
    padding: 70px 20px 20px;

    .sitename__header {
      display: block;
      height: auto;
      margin: 40px auto;
      color: #b62830;
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
      text-transform: uppercase;
      border: none;
    }

    .zvonok a,
    .zayavka a {
      display: inline-block;
      width: 100%;
      margin: 0;
      padding: 6px 15px 8px;
      color: #647b98;
      font-size: 16px;
      line-height: 1.4;
      text-transform: uppercase;
      text-decoration: none !important;
      font-weight: 700;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
      border-radius: 3px;
      background-color: #647b98;
      background-image: linear-gradient(to top, #e0e0e0 0%, #f9f9f9 100%);
    }

    #logo {
      display: none;
    }
  }

  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    padding: 40px;

    .sitename__header {
      margin-bottom: 60px;
    }

    .zvonok a,
    .zayavka a {
      width: 45%;
    }
  }

  @media (min-width: $screen-lg) {
    float: left;
    width: 520px;
    height: 140px;
    background: url(../images/bg-sitename.png) 100% 0 no-repeat;
  }
}

#sitename-left {
  float: left;
  width: 200px;
  height: 140px;
  background: url(../images/bg-sitename.png) 100% 0 no-repeat;

  @media (max-width: $screen-sm-max) {
    display: none;
  }
}

#sitename-right {
  float: left;
  width: 280px;
  height: 140px;
  background: transparent;

  @media (max-width: $screen-sm-max) {
    display: none;
  }
}