#right {
  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    float: left;
    width: 40%;
    padding-left: 10px;
    padding-top: 50px;
  }

  @media (min-width: $screen-lg) {
    float: left;
    width: 240px;
    padding-left: 10px;
    margin-top: 60px;
    margin-bottom: 30px;
    margin-left: 10px;
    text-align: left;
  }
}

.front-page #right {
  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    padding-top: 67px;
  }
}

#right h3 {
  margin-bottom: 15px;
  margin-top: 0;
  padding-left: 19px;
  font-size: 18px;
  text-align: left;
  text-transform: uppercase;
  color: #b62830;

  @media (max-width: $screen-sm-max) {
    padding-left: 0;
    text-align: center;
  }
}

#right .moduletablesuf {
  @media (max-width: $screen-sm-max) {
    &:first-of-type {
      //блок новости
      h3 {
        position: relative;
        top: 65px;
        padding-left: 0;
      }
    }

    &:last-of-type {
      //блок с акцией
      margin: 0 10px;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #bdbdbd;
      background: -webkit-gradient(linear,left top,left bottom,from(#f8f8f8),to(#f2f2f2));
      box-shadow: 0 1px 5px #bfbfbf;
      text-shadow: 0 1px 0 #fff;
      border-radius: 0;

      h3 {
        padding: 15px 0 17px;
        border-bottom: 1px solid #e8e9e9;
      }
    }
  }
}

#right ul {
  padding-left: 20px;
}

#right .moduletable_menu,
#right .moduletable {
  margin-bottom: 30px;
  padding-bottom: 20px;
}

#right .moduletable {
  @media (max-width: $screen-sm-max) {
    margin: 0 10px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #bdbdbd;
    background: -webkit-gradient(linear,left top,left bottom,from(#f8f8f8),to(#f2f2f2));
    box-shadow: 0 1px 5px #bfbfbf;
    text-shadow: 0 1px 0 #fff;
    border-radius: 0;
    margin-bottom: 20px;

    .joomla_ass {
      padding-top: 20px;
    }

    .box_skitter {
      position: relative;
      left: 5px;
    }
  }
}

#right .moduletablesuf {
  margin-bottom: 30px;
  padding-top: 7px;
  padding-bottom: 20px;
  text-shadow: 0 1px 0 white;

  @media (min-width: $screen-lg) {
    border-top: 1px solid white;
    border-bottom: 1px solid #bdbdbd;
    border-radius: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(#f8f8f8), to(#f2f2f2));
    box-shadow: 0 1px 5px #bfbfbf;
  }
}