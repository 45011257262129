
.pagewidth {
  min-width: 300px;
  width: calc(100% - 20px);
  text-align: center;
  background: transparent;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;

  @media (min-width: $screen-sm) {
    min-width: $screen-sm;
    width: 80%;
  }

  @media (min-width: $screen-lg) {
    width: $screen-lg;
    position: relative; // ??
  }
}
