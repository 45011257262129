/**
 * Стили для таблицы Бухгатлерское сопровождение
 *
 * uslugi/bukhgalterskie-uslugi/bukhgalterskoe-soprovozhdenie
 */

@mixin table-caption($content) {
  p::before {
    font-size: 0.8em;
    content: $content;
  }
}

.table-responsive .account-support {
  tr {
    &:nth-of-type(2),
    &:nth-of-type(3) {
      display: none;
    }

    &:nth-of-type(5),
    &:nth-of-type(8),
    &:nth-of-type(11),
    &:nth-of-type(14),
    &:nth-of-type(17),
    &:nth-of-type(20),
    &:nth-of-type(23),
    &:nth-of-type(26) {
      @include table-caption('Индивидуальные предприниматели: ');
    }
    &:nth-of-type(6),
    &:nth-of-type(9),
    &:nth-of-type(12),
    &:nth-of-type(15),
    &:nth-of-type(18),
    &:nth-of-type(21),
    &:nth-of-type(24),
    &:nth-of-type(27) {
      @include table-caption('Юридические лица: ');
    }
  }


}

