.footer-services {
  @media (max-width: $screen-sm-max) {
    padding: 0 10px;

    h3 {
      height: auto;
      padding-bottom: 10px;
      border-bottom: 1px solid #e8e9e9;
    }
  }
}

.footer-services__item {
  @media (max-width: $screen-sm-max) {
    width: 100%;
    margin-bottom: 20px;

    a {
      display: block;
      margin: 0 auto;
      text-decoration: none;
      text-align: center;
      color: #333333;
      font-size: 18px;
      font-weight: 400;
    }

    img {
      display: block;
      height: auto;
      max-width: 100%;
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }

  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    float: left;
    width: 33.3333%;
    padding: 10px;
  }
}