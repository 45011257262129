@import "common/variables";
@import "common/animations";

// Старые стили проекта
@import "legacy/tdefaut";
@import "legacy/box";
@import "legacy/skitter";
@import "legacy/breezing-forms";


@media (min-width: $screen-lg) {
  @import "legacy/latest-news";
}


// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
// endbower


@import "common/global";


@import "layouts/wrapper";
@import "layouts/pagewidth";
@import "layouts/box";


@import "components/buttons";
@import "components/box";
@import "components/navigation";
@import "components/topmenu";
@import "components/sitename";
@import "components/sidebar";
@import "components/main";
@import "components/item-page";
@import "components/pluso";
@import "components/glavbut";
@import "components/advantages";
@import "components/newslist";
@import "components/footer-contacts";
@import "components/footer-services";
@import "components/top";
@import "components/ftb";
@import "components/map";
@import "components/contacts";
@import "components/pagination";
@import "components/table-responsive";
@import "components/nice-menu";
@import "components/breezing-forms";
@import "components/account-support";


@import "pages/kontakty";


@import "tmp";