// таблица на странице контакты
.table-responsive {
  @media (max-width: $screen-sm-max) {

    td {
      //width: 100% !important;
      vertical-align: middle;
      text-align: center;

      p {
        line-height: 1;
      }
    }

    iframe {
      width: auto !important;

      * {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {

  #vk_groups {
    width: auto !important;
  }

}