#top {
  float: left;
  width: 30px;
  height: 30px;
  margin-top: 9px;
  padding: 0 !important;
  border: none !important;
  background: transparent;

  @media (max-width: $screen-sm-max) {
    display: none;
  }

  @media (min-width: $screen-lg) {

  }
}

.top_button {
  padding: 0 !important;
  border: none !important;
}