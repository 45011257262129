@keyframes expand {
  0% {
    width: 5px;
    padding-left: 0;
  }

  100% {
    width: 180px;
    padding-left: 10px;
  }
}

@keyframes expand-bounce {
  0% {
    width: 5px;
    padding-left: 0;
  }

  50% {
    width: 180px;
  }

  70% {
    width: 170px;
  }

  80% {
    width: 180px;
  }

  90% {
    width: 180px;
  }

  100% {
    width: 180px;
    padding-left: 10px;
  }
}


@keyframes shrink {
  0% {
    width: 180px;
    padding-left: 10px;
  }

  100% {
    width: 5px;
    padding-left: 0;
  }
}

@keyframes shrink-bounce {
  0% {
    width: 180px;
    padding-left: 10px;
  }

  50% {
    width: 5px;
  }

  70% {
    width: 35px;
  }

  80% {
    width: 5px;
  }

  90% {
    width: 15px;
  }

  100% {
    width: 5px;
    padding-left: 0;
  }
}

