/* Skitter styles */
@font-face {
  font-family: 'BebasNeueRegular';
  src: url('../fonts/bebas-neue-fontfacekit/BebasNeue-webfont.eot');
  src: url('../fonts/bebas-neue-fontfacekit/BebasNeue-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/bebas-neue-fontfacekit/BebasNeue-webfont.woff') format('woff'),
  url('../fonts/bebas-neue-fontfacekit/BebasNeue-webfont.ttf') format('truetype'),
  url('../fonts/bebas-neue-fontfacekit/BebasNeue-webfont.svg#BebasNeueRegular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'CaviarDreamsBold';
  src: url('../fonts/Caviar-Dreams-fontfacekit/CaviarDreams_Bold-webfont.eot');
  src: url('../fonts/Caviar-Dreams-fontfacekit/CaviarDreams_Bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Caviar-Dreams-fontfacekit/CaviarDreams_Bold-webfont.woff') format('woff'),
  url('../fonts/Caviar-Dreams-fontfacekit/CaviarDreams_Bold-webfont.ttf') format('truetype'),
  url('../fonts/Caviar-Dreams-fontfacekit/CaviarDreams_Bold-webfont.svg#CaviarDreamsBold') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'LillyRegular';
  src: url('../fonts/Lilly-fontfacekit/Lilly__-webfont.eot');
  src: url('../fonts/Lilly-fontfacekit/Lilly__-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lilly-fontfacekit/Lilly__-webfont.woff') format('woff'),
  url('../fonts/Lilly-fontfacekit/Lilly__-webfont.ttf') format('truetype'),
  url('../fonts/Lilly-fontfacekit/Lilly__-webfont.svg#LillyRegular') format('svg');
  font-weight: normal;
  font-style: normal;

}




.box_skitter {position:relative;width:800px;height:300px;background:#000;margin-left: -10px !important;}

.box_skitter ul {display:none; }

.box_skitter .container_skitter {z-index: 0 !important; overflow:hidden;position:relative;}

.box_skitter .image {overflow:hidden;}

.box_skitter .image img {display:none;}



.box_skitter .box_clone {position:absolute;top:0;left:0;width:100px;overflow:hidden;display:none;z-index:1;}

.box_clone img {position:absolute;top:0;left:0; z-index:1;}



.prev_button {position:absolute;top:50%;left:10px;z-index:100;width:42px;height:42px;overflow:hidden;text-indent:-9999em;margin-top:-25px;background:url(../images/prev.png) no-repeat left top;}

.box_skitter .next_button {position:absolute;top:50%;right:10px;z-index:100;width:42px;height:42px;overflow:hidden;text-indent:-9999em;margin-top:-25px;background:url(../images/next.png) no-repeat left top;}



.box_skitter .info_slide {position:absolute;top:15px;left:15px;background:#000;color:#fff;font:bold 11px arial;padding:5px 0 5px 5px;-moz-border-radius:5px;-webkit-border-radius:5px;border-radius:5px;opacity:0.75;z-index:2;}

.box_skitter .info_slide .image_number {background:#333;float:left;padding:2px 10px;margin:0 5px 0 0;cursor:pointer;-moz-border-radius:2px;-webkit-border-radius:2px;border-radius:2px;}

.box_skitter .info_slide .image_number_select {background:#cc0000;float:left;padding:2px 10px;margin:0 5px 0 0;}



.box_skitter .container_thumbs {position:relative;overflow:hidden;height:50px;}

.box_skitter .info_slide_thumb {-moz-border-radius:0;-webkit-border-radius:0;border-radius:0;overflow:hidden;height:45px;top:auto;bottom:-5px;left:-5px;padding:5px;opacity:1.0;}

.box_skitter .info_slide_thumb .image_number {overflow:hidden;width:70px;height:40px;position:relative;}

.box_skitter .info_slide_thumb .image_number img {position:relative;top:-50px;left:-50px}



.box_skitter .box_scroll_thumbs {padding:0 10px;}

.box_skitter .box_scroll_thumbs .scroll_thumbs {position:absolute;bottom:60px;left:50px;background:#ccc;background:-moz-linear-gradient(-90deg, #555, #fff);background:-webkit-gradient(linear, left top, left bottom, from(#555), to(#fff));width:200px;height:10px;overflow:hidden;text-indent:-9999em;z-index:101;-moz-border-radius:20px;-webkit-border-radius:20px;border-radius:20px;cursor:pointer;border:1px solid #333;}



.box_skitter .info_slide_dots {position:absolute;bottom:-40px;z-index:100;padding:5px 0 5px 5px;-moz-border-radius:50px;-webkit-border-radius:50px;border-radius:50px;}

.box_skitter .info_slide_dots .image_number {background:#333;float:left;margin:0 5px 0 0;cursor:pointer;-moz-border-radius:50px;-webkit-border-radius:50px;border-radius:50px;width:18px;height:18px;text-indent:-9999em;overflow:hidden;position:relative;}

.box_skitter .info_slide_dots .image_number_select {background:#cc0000;float:left;margin:0 5px 0 0;}



.box_skitter .label_skitter {z-index:50;position:absolute;bottom:0px;left:0px;display:none;}



.loading {position:absolute; top:50%; right:50%; z-index:10000; margin:-16px -16px;color:#fff;text-indent:-9999em;overflow:hidden;background:url(../images/ajax-loader.gif) no-repeat left top;width:32px;height:32px;}



/**

 * Example for dimenions:

 */



.box_skitter_small {width:200px;height:200px;}

.label_skitter {z-index:50;position:absolute;bottom:0px;left:0px;background:url(../images/back-box-label-black.png) repeat-x left top;color:#fff;display:none;border-top:1px solid #000;}

.label_skitter p {}

.info_slide * {font-family:Consolas,arial,tahoma !important;}