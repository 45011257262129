.footer-contacts {

  @media (max-width: $screen-sm-max) {
    .custom > .table-responsive,
    .custom > table,
    .custom > p {
      display: none;
    }

    .footer-contacts__mobile {

    }

    .footer-contacts__header {
      color: #343434;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
    }

    .footer-contacts__phone {
      display: inline-block;
      width: 100%;
      margin: 30px 0;
      color: #343434;
      font-size: 20px;
      text-align: center;

      span {
        color: #b5282f;
        font-size: 22px;
        font-weight: 700;
      }
    }

    .footer-contacts__buttons {
      padding: 0 10px;

      a {
        display: inline-block;
        margin-bottom: 15px;
        padding: 10px;
        width: 100%;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
        border-radius: 3px;
        background-color: #b5282f;
        font-size: 16px;
        line-height: 30px;
        font-weight: 700;
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
      }

    }
  }

  @media (min-width: $screen-lg) {
    .footer-contacts__mobile {
      display: none;
    }
  }

  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    .footer-contacts__buttons {
      display: flex;
      justify-content: center;
      margin-bottom: 50px;

      a {
        margin: 0 20px;
        width: 45%;
      }
    }
  }
}

